
import "./css/LoginEspaceProSection.css";
import "./css/LoginEspaceProSectionMobile.css";
import PinkButton from "../../components/buttons/PinkButton";
import { Breadcrumbs, Link, IconButton } from "@mui/material";
import WhiteButton from "../../components/buttons/whiteButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import { useTheme } from "../../context/ThemeContext";
import { useMediaQuery } from 'react-responsive';



const LoginEspaceProSection = () => {
    const { isDarkMode } = useTheme();
    const isMobile = useMediaQuery({ maxWidth: 1252 });
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("submit");
    };

    const [password, setPassword] = useState("");
    const handlePassword = (e) => {
        setPassword(e.target.value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className={isDarkMode ? "dark-background content-login dark-text" : "content-login"}>
            {isMobile ? (
                <></>
            ) :
            (
            <div className="gray-background padding-login part-image">
            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs part-image-content">
                <Link underline="hover" color="var(--primary-dark)" href="/">
                    &lt; Accueil
                </Link>
                <Link underline="hover" color="white" href="/en-construction" className="link-breadcrumb">
                    Connexion à l'espace pro
                </Link>
            </Breadcrumbs>
        </div>
        )}
            

            <div className="part-login padding-login">
                <h3 className={isDarkMode ? "dark-text center-text" : "center-text"}>Connexion</h3>
                <div className="lobster-title gap-32">Espace Pro</div>
                <form onSubmit={handleSubmit} className="form-login">
                    <label htmlFor='email' className={isDarkMode ? "form-label-dark" : "form-label"}> Adresse email* </label>
                    <input type="text" name="email" placeholder="Email" className="form-input" />

                    <label htmlFor='password' className={isDarkMode ? "form-label-dark" : "form-label"}>Mot de passe*</label>
                    <div className="form-input display-password">
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Mot de passe"
                            value={password}
                            onChange={handlePassword}
                            required={true}
                            className="password-input"
                        />
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                            className="password-toggle-icon"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </div>

                    <div className="checkbox-line">
                        <input type="checkbox" name="stay-connected" id="size_3" value="large" className="checkbox" />
                        <label htmlFor="size_3" className={` ${isDarkMode ? "form-label-dark" : "form-label"}`}>
                            Rester connecté.e
                        </label>
                    </div>

                </form>

                <div className="password-forgot">
                    <Link href="#" underline="hover" color="var(--primary-dark)">Mot de passe oublié</Link>
                </div>

                <PinkButton text="Se connecter" link="#" styleprop="button-login" />
                <div className="line-or">
                    <div className={isDarkMode ? "line-dark" : "line"}></div> <div>ou</div> <div className={isDarkMode ? "line-dark" : "line"}></div>
                </div>
                <WhiteButton text="Créer un compte" link="#" styleprop="button-login" />
            </div>
        </div>
    )

};

export default LoginEspaceProSection;