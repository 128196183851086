
import { useEffect, useState } from "react";
import { useTheme } from "../../context/ThemeContext";
import "./css/blogSection.css";
import "./css/blogSectionMobile.css";
import JsonBlog from "../../json/blog.json";
import next from "../../assets/svg/right.svg";
import { Link } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const BlogSection = () => {

    const { isDarkMode } = useTheme();
    const [blogData, setBlogData] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState({});

    useEffect(() => {
        setBlogData(JsonBlog.data);
    }, []);

    const handleClickOpen = (id) => {
        setSelectedArticle(blogData[id]);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div id="blog" className={isDarkMode ? "dark-background content-blog dark-text" : "content-blog"}>
            <h3 className={isDarkMode ? "dark-text" : ""}>On vous conseille</h3>
            <h2>Notre blog</h2>
            <div className="blog-bloc">
                {blogData.map((article, index) => (
                    <div className="blog-card" key={article.id}>
                        <img src={require(`../../assets/images/${article.image}`)} alt="placeholder" className="image-blog" />
                        <p className={isDarkMode ? "date-dark" : "date"}>{article.date}</p>
                        <h4 className={isDarkMode ? "dark-text" : ""}>{article.title}</h4>
                        <Link onClick={() => handleClickOpen(index)} className="link-blog"> Lire l'article<img src={next} alt="icone suivant" /></Link>
                    </div>
                ))}
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    {selectedArticle.image && (
                        <img src={require(`../../assets/images/${selectedArticle.image}`)} className="modal-image-blog" alt={selectedArticle.title} />
                    )}
                </DialogTitle>
                <DialogTitle>
                    <p className={isDarkMode ? "date-dark" : "date"}>{selectedArticle.date}</p>
                    <h4 className={isDarkMode ? "dark-text" : ""}>{selectedArticle.title}</h4>
                </DialogTitle>
                <DialogContent><p>{selectedArticle.content}</p></DialogContent>
                <DialogActions>
                <Link onClick={handleClose} className="close-modal"> Fermer l'article</Link>
                </DialogActions>
            </Dialog>
        </div>
    )

};

export default BlogSection;