
import PinkButton from "../../components/buttons/PinkButton";
import WhiteButton from "../../components/buttons/whiteButton";
import { useTheme } from "../../context/ThemeContext";
import "./css/footer.css";
import "./css/footerMobile.css";
import Linkedin from "../../assets/svg/LinkedIn.svg";
import Facebook from "../../assets/svg/Facebook.svg";
import Instagram from "../../assets/svg/Instagram.svg";
import { Link } from "react-router-dom";

const Footer = () => {
    const { isDarkMode } = useTheme();
    let year = new Date().getFullYear();

    return (
        <>
            <div className={isDarkMode ? "gray-background dark-text big-content-footer" : "big-content-footer pink-background"}>
                <div className="content-footer">
                    <div className="footer-title">On reste en contact ?</div>
                    <div className="footer-subtitle">Contactez-nous par mail et <br />suivez-nous sur les réseaux sociaux 👇</div>
                    <div className="footer-button">
                        <WhiteButton link="mailto:capink.trouvetontatoueur@gmail.com" text="Contactez-nous par mail" minWidth="190px" />
                        <PinkButton link="https://airtable.com/appAreq3j3z61cdjm/shrrIJ7vqwDrdnYgf" text="M'alerter du lancement" minWidth="190px" />
                    </div>
                    <div className="footer-network">
                        <a href="https://www.instagram.com/capink_trouvetontatoueur"><img src={Instagram} alt="Instagram" className="social-icon" /></a>
                        <a href="https://www.facebook.com/profile.php?id=100090948903565"><img src={Facebook} alt="Facebook" className="social-icon" /></a>
                        <a href="https://linkedin.com/company/capink"><img src={Linkedin} alt="Linkedin" className="social-icon" /></a>
                    </div>
                </div>

                <div className="content-footer-bloc">
                    <div className="footer-bloc">
                    <h3 className={isDarkMode ? "dark-text" : ""}>à propos de CapInk</h3>
                        <div className="footer-link">
                            <div><Link to="en-construction" className={isDarkMode ? "dark-text no-decoration" : "no-decoration"}>Notre raison d'être</Link></div>
                            <div><Link to="en-construction" className={isDarkMode ? "dark-text no-decoration" : "no-decoration"}>L'équipe CapInk</Link></div>
                            <div><Link to="en-construction" className={isDarkMode ? "dark-text no-decoration" : "no-decoration"}>Partenariats</Link></div>
                        </div>
                    </div>

                    <div className="footer-bloc">
                    <h3 className={isDarkMode ? "dark-text" : ""}>Navigation</h3>
                        <div className="footer-link">
                            <div><Link to="en-construction" className={isDarkMode ? "dark-text no-decoration" : "no-decoration"}>FAQ</Link></div>
                            <div><Link to="en-construction" className={isDarkMode ? "dark-text no-decoration" : "no-decoration"}>Blog</Link></div>
                            <div><Link to="/accueil-tatoueur" className={isDarkMode ? "dark-text no-decoration" : "no-decoration"}>Offre Tatoueur.se</Link></div>
                        </div>
                    </div>

                    <div className="footer-bloc">
                    <h3 className={isDarkMode ? "dark-text" : ""}>Informations</h3>
                        <div className="footer-link">
                            <div><Link to="en-construction" className={isDarkMode ? "dark-text no-decoration" : "no-decoration"}>CGV</Link></div>
                            <div><Link to="en-construction" className={isDarkMode ? "dark-text no-decoration" : "no-decoration"}>Politique de confidentialité</Link></div>
                            <div><Link to="en-construction" className={isDarkMode ? "dark-text no-decoration" : "no-decoration"}>Mentions Légales</Link></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-footer">
                <div>©Tous droits réservés CapInk - {year}</div>
                <div className="droit-footer">
                    <Link to="en-construction" className="no-decoration">Plan du site</Link>
                </div>

            </div>
        </>
    )
};

export default Footer;