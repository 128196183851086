import { Outlet, Link } from "react-router-dom";
import LogoCapinkFondBlanc from "../../assets/images/LogoCapinkFondBlanc.png";
import LogoCapinkFondDark from "../../assets/images/LogoCapinkFondDark.png";
import Moon from "../../assets/svg/moon.svg";
import Sun from "../../assets/svg/sun.svg";
import "./css/HeaderCommon.css";
import "./css/MobileHeader.css";
import { useTheme } from "../../context/ThemeContext";
import WhiteButton from "../../components/buttons/whiteButton";
import PinkButton from "../../components/buttons/PinkButton";
import { useMediaQuery } from 'react-responsive';
import Burger from "../../assets/svg/Burger.svg";
import WhiteBurger from "../../assets/svg/WhiteBurger.svg";
import { Drawer, IconButton } from "@mui/material";
import { useState } from "react";
import closeCross from "../../assets/svg/closeCross.svg";
import WhiteCloseCross from "../../assets/svg/WhiteCloseCross.svg";


const Header = () => {
  const { isDarkMode, toggleDarkMode } = useTheme();
  const [showDrawer, setShowDrawer] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 1610 });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setShowDrawer((prevMode => !prevMode));
  };

  return (
    <>
      {isMobile ? (
        <div className={isDarkMode ? "contenant-header shadow dark-background" : "contenant-header shadow light-background"}>
          <div><IconButton size="large" onClick={toggleDrawer('left', true)}><img src={isDarkMode ? WhiteBurger : Burger} alt="menu burger" className="burger" /></IconButton></div>
          <Drawer
            anchor={'left'}
            open={showDrawer}
            onClose={toggleDrawer('left', false)}
            PaperProps={isDarkMode ? { className: 'drawer-content-dark' } : { className: 'drawer-content' }}
          >
            <div className="head-menu">
              <IconButton size="large" onClick={toggleDrawer('left', false)}><img src={isDarkMode ? WhiteCloseCross : closeCross} alt="bouton clore" /></IconButton>
              <div className="button"><button className={isDarkMode ? "toggle-dark" : "toggle"} onClick={toggleDarkMode}><img src={isDarkMode ? Sun : Moon} alt='moon icon' /></button></div>
            </div>
            <div className="link-header-menu">
              <div className={isDarkMode ? "text-link-header-dark" : "text-link-header"}><Link to="/en-construction" onClick={toggleDrawer('left', false)}>Notre raison d'être</Link></div>
              <div className={isDarkMode ? "text-link-header-dark" : "text-link-header"}><Link to="#faq" onClick={toggleDrawer('left', false)}>FAQ</Link></div>
              <div className={isDarkMode ? "text-link-header-dark" : "text-link-header"}><Link to="#blog" onClick={toggleDrawer('left', false)}>Blog</Link></div>
              <div className={isDarkMode ? "text-link-header-dark" : "text-link-header"}><Link to="/accueil-tatoueur" onClick={toggleDrawer('left', false)}>Offre Tatoueur.se</Link></div>
            </div>
            <div className="button-header-menu">
            <div><WhiteButton link="https://airtable.com/appAreq3j3z61cdjm/shrrIJ7vqwDrdnYgf" styleprop="button-menu" text="🔔 Être alerté du lancement"/></div>
              <div><PinkButton link="/espace-pro" text="Espace Pro" styleprop="button-menu" /></div>
              </div>
          </Drawer>
          <div className="logo-header">
            <div><Link to="/"><img src={isDarkMode ? LogoCapinkFondDark : LogoCapinkFondBlanc} alt='logo CapInk' className="logo" /></Link></div>
          </div>
          <div className="button"><button className={isDarkMode ? "toggle-dark" : "toggle"} onClick={toggleDarkMode}><img src={isDarkMode ? Sun : Moon} alt='moon icon' /></button></div>
        </div>
      ) : (
        <>
          <div className={isDarkMode ? "contenant-header shadow dark-background" : "contenant-header shadow light-background"}>
            <div className="logo-header">
              <div><Link to="/"><img src={isDarkMode ? LogoCapinkFondDark : LogoCapinkFondBlanc} alt='logo CapInk' className="logo" /></Link></div>
            </div>

            <div className={isDarkMode ? "link-header dark-text" : "link-header"}>
              <div className="text-link-header"><Link to="/en-construction">Notre raison d'être</Link></div>
              <div className="text-link-header"><Link to="#faq">FAQ</Link></div>
              <div className="text-link-header"><Link to="#blog">Blog</Link></div>
              <div className="text-link-header"><Link to="/accueil-tatoueur">Offre Tatoueur.se</Link></div>
            </div>

            <div className="button-header">
              <div><WhiteButton link="https://airtable.com/appAreq3j3z61cdjm/shrrIJ7vqwDrdnYgf" styleprop="button" text="🔔 Être alerté du lancement" minWidth="258px" /></div>
              <div><PinkButton link="/espace-pro" styleprop="button" text="Espace Pro" minWidth="90px" /></div>
              <div className="button"><button className={isDarkMode ? "toggle-dark" : "toggle"} onClick={toggleDarkMode}><img src={isDarkMode ? Sun : Moon} alt='moon icon' /></button></div>
            </div>
          </div> <Outlet />
        </>
      )}
    </>
  )
};

export default Header;